<template>
  <div class="panel-content-right">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="header">
          <div>Delete</div>
        </div>
        <div class="content mt-3 h-stretch">
          Are you sure you want to delete "{{
            selectedNote ? selectedNote.title : "null"
          }}" ?
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="deleteNote()" class="edit">Delete</button>
        </div>
      </div>
    </div>

    <div ref="dialog" class="overlay">
      <div class="card">
        <div class="content">
          <div class="v-stack gap-5">
            <a class="heading-title-2">Remove</a>
            <div>
              Do you wish to stop sharing this note with
              {{ selectedUser ? selectedUser.fullName : "No User Error" }}?
            </div>
            <div class="h-stack h-space-between">
              <button class="submit" @click="dismissRemoveDialog()">
                Cancel
              </button>
              <button class="edit" @click="removeUser()">Remove</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="panel-left v-stretch h-stretch">
      <div class="pane-top h-stretch gap-3 hide-y note-list">
        <div class="h-stack h-space-between">
          <div class="h-stack gap-3">
            <label style="align-self: center">Shared:</label>
            <input type="checkbox" v-model="showShared" />
          </div>
          <div class="h-stack gap-3">
            <template
              v-if="
                selectedNote &&
                selectedNote.user == $store.state.id &&
                notes.filter((note) => note.user == $store.state.id).length > 1
              "
            >
              <button class="submit icon-button" @click="noteUp()">
                <i class="fas fa-arrow-up"></i>
              </button>
              <button class="submit icon-button" @click="noteDown()">
                <i class="fas fa-arrow-down"></i>
              </button>
            </template>
            <div
              v-if="!selectedNote || selectedNote.user != $store.state.id"
            ></div>
            <button
              v-if="selectedNote && selectedNote.user == $store.state.id"
              class="edit"
              @click="deleteDialog()"
            >
              x
            </button>
            <button class="add" @click="createNote()">+</button>
          </div>
        </div>
        <div
          class="v-stack h-stretch hide-y"
          v-bind:class="[this.notes.length == 0 ? 'v-center' : 'v-start']"
        >
          <div
            class="v-stack h-stretch v-stretch gap-1"
            style="overflow-y: auto"
          >
            <div
              class="text-left item"
              v-for="note in filteredNotes"
              v-bind:class="[note == selectedNote ? 'selected' : '']"
              :key="note._id"
              @click="startEditingNoteTitle(note)"
            >
              <div class="h-stack h-space-between border-item">
                <div class="note-title" v-if="!note.edit">{{ note.title }}</div>
                <input
                  class="note-name"
                  type="text"
                  v-model="note.title"
                  v-if="note.edit"
                  placeholder="name your note"
                  @keydown.enter="updateNoteTitle(note)"
                  v-on:focusout="updateNoteTitle(note)"
                  v-focus
                />
                <div class="h-stack gap-3">
                  <div v-if="note.user != $store.state.id">
                    ({{ getUserName(note.user) }})
                  </div>
                  <div
                    class="h-stack gap-2"
                    v-if="
                      note.shared.length > 0 && note.user == $store.state.id
                    "
                  >
                    <i class="fas fa-users"></i>
                    <div>{{ note.shared.length }}</div>
                  </div>
                  <div v-if="!note.saved">!</div>
                </div>
              </div>
            </div>
            <div v-if="this.notes.length == 0" style="border: none">
              Create a new note with the + button
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="selectedNote">
      <div class="chat-panel h-stack h-space-between">
        <div class="h-stack h-start gap-3 recipients">
          <div
            class="chat-icon"
            v-for="user in selectedNote.shared"
            :key="user._id"
            @click="showRemoveDialog(user)"
          >
            <div class="chat-icon-initials">
              {{
                user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()
              }}
            </div>
            <div class="tooltip">{{ user.fullName }}</div>
            <div class="remove">X</div>
          </div>
        </div>
        <div class="h-stack h-start gap-3 note-editable">
          <template v-if="selectedNote.user == $store.state.id">
            <label style="align-self: center">Others can edit:</label>
            <input
              v-model="selectedNote.editable"
              @change="changeDetected()"
              type="checkbox"
              style="align-self: center"
            />
          </template>
          <div class="people-search-container">
            <input
              id="searchInput"
              class="people-search"
              v-model="search"
              placeholder="Search for recipients..."
              v-on:focusin="searchActive = true"
              v-on:focusout="looseFocus()"
            />
            <div
              class="v-stack people-search-list h-stretch"
              v-show="searchActive"
            >
              <div class="people-search-item" @click="addAllUsers()">All</div>
              <div
                class="people-search-item"
                v-for="user in filteredUsers"
                :key="user._id"
                @click="shareWithUser(user, true)"
              >
                {{ user.fullName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <template
          v-if="selectedNote.user == $store.state.id || selectedNote.editable"
        >
          <label class="text-right note-state" v-if="!selectedNote.saved"
            >Saving changes...</label
          >
        </template>
        <textarea
          v-bind:disabled="
            selectedNote.user != $store.state.id && !selectedNote.editable
          "
          v-model="selectedNote.message"
          @input="changeDetected()"
        ></textarea>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import EventBus from "@/eventbus.js";
import Fuse from "fuse.js";
import Vue from "vue";

export default {
  data() {
    return {
      selectedNote: null,
      selectedUser: null,
      showShared: true,
      notes: [],
      users: [],
      searchActive: false,
      doubleClickStarted: false,
      search: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    document.getElementById("page-footer").style.display = "none";
    next();
  },
  beforeRouteLeave(to, from, next) {
    document.getElementById("page-footer").style.display = "grid";
    next();
  },
  computed: {
    filteredNotes() {
      return this.notes.filter((note) => {
        if (!this.showShared) {
          return note.user == this.$store.state.id;
        }
        return true;
      });
    },
    filteredUsers() {
      let users = this.users;

      const query = this.search.trim();
      if (query.length > 0) {
        const options = {
          keys: ["fullName"],
          threshold: 0.3,
          ignoreLocation: true,
        };
        const fuse = new Fuse(users, options);
        users = fuse.search(query).map((resultItem) => resultItem.item);
      }

      return users
        .filter((user) => {
          if (user._id == this.$store.state.id) {
            return false;
          }
          for (const sharedUser of this.selectedNote.shared) {
            if (user._id == sharedUser._id) {
              return false;
            }
          }
          return true;
        })
        .sort((a, b) => {
          return a.fullName
            .toLowerCase()
            .localeCompare(b.fullName.toLowerCase(), "cs");
        });
    },
  },
  methods: {
    ...mapActions([
      "getNotes",
      "addNote",
      "removeNote",
      "editNote",
      "getUsers",
      "moveNoteUp",
      "moveNoteDown",
    ]),
    showRemoveDialog(user) {
      this.selectedUser = user;
      this.$refs.dialog.classList.add("show");
    },
    dismissRemoveDialog() {
      this.$refs.dialog.classList.remove("show");
    },
    removeUser() {
      this.dismissRemoveDialog();
      if (this.selectedNote && this.selectedUser) {
        this.shareWithUser(this.selectedUser, false);
      }
    },
    looseFocus() {
      const element = document.elementFromPoint(Vue.mouseX, Vue.mouseY);
      if (!element.classList.contains("people-search-item")) {
        this.searchActive = false;
      }
    },
    addAllUsers() {
      for (const user of this.filteredUsers) {
        this.shareWithUser(user, true);
      }

      this.search = "";
      this.searchActive = false;
    },
    startEditingNoteTitle(note) {
      this.selectedNote = note;

      if (note.user != this.$store.state.id) {
        return;
      }

      if (this.doubleClickStarted) {
        note.edit = true;
        this.$forceUpdate();
      } else {
        this.doubleClickStarted = true;
        setTimeout(() => {
          this.doubleClickStarted = false;
        }, 300);
      }
    },
    noteUp() {
      if (this.selectedNote) {
        const index = this.notes.indexOf(this.selectedNote);

        if (index > -1) {
          this.moveNoteUp(index)
            .then(() => {
              this.refresh();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    noteDown() {
      if (this.selectedNote) {
        const index = this.notes.indexOf(this.selectedNote);

        if (index > -1) {
          this.moveNoteDown(index)
            .then(() => {
              this.refresh();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    getUserName(id) {
      for (const user of this.users) {
        if (user._id == id) {
          return user.fullName;
        }
      }
      return "";
    },
    updateNoteTitle(note) {
      note.edit = false;
      this.changeDetected();
    },
    changeDetected() {
      if (
        this.selectedNote &&
        (this.selectedNote.user == this.$store.state.id ||
          this.selectedNote.editable)
      ) {
        const note = this.selectedNote;
        note.saved = false;

        if (note.timer) {
          clearTimeout(note.timer);
        }

        note.timer = setTimeout(() => {
          note.timer = null;
          this.editNote({
            _id: note._id,
            title: note.title,
            message: note.message || " ",
            shared: note.shared.map((element) => element._id),
            index: note.index,
            editable: note.editable,
          })
            .then(() => {
              note.saved = true;
              this.showNotification(note.title + " saved!");
            })
            .catch((error) => {
              console.log(error);
            });
        }, 1000);
      }
    },
    createNote() {
      const note = {
        title: "Note",
        message: " ",
      };
      this.addNote(note)
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteNote() {
      this.dismiss();
      if (this.selectedNote) {
        this.removeNote(this.selectedNote._id)
          .then(() => {
            this.selectedNote = null;
            this.refresh();
          })
          .catch((error) => {
            this.console.log(error);
          });
      }
    },
    deleteDialog() {
      if (this.selectedNote) {
        this.$refs.overlay.classList.add("show");
      }
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
    showNotification(message) {
      EventBus.$emit("message", {
        text: message,
      });
    },
    shareWithUser(user, share) {
      this.search = "";
      this.searchActive = false;

      if (user && this.selectedNote) {
        if (share) {
          let add = true;
          for (const sharedUser of this.selectedNote.shared) {
            if (user._id == sharedUser._id) {
              add = false;
            }
          }
          if (add) {
            this.selectedNote.shared.push(user);
          }
        } else {
          for (const [i, sharedUser] of this.selectedNote.shared.entries()) {
            if (sharedUser._id == user._id) {
              this.selectedNote.shared.splice(i, 1);
              break;
            }
          }
        }
      }

      this.changeDetected();
    },
    refresh() {
      this.getNotes()
        .then((notes) => {
          this.notes = notes.map((note) => {
            const temp = note;
            note.saved = true;

            let shared = [];
            for (const user of this.users) {
              for (const userID of note.shared) {
                if (userID == user._id) {
                  shared.push(user);
                }
              }
            }
            note.shared = shared;

            return temp;
          });

          if (this.notes.length > 0) {
            if (this.selectedNote) {
              for (const note of this.notes) {
                if (this.selectedNote._id == note._id) {
                  this.selectedNote = note;
                  return;
                }
              }
            }
          }

          this.selectedNote = this.notes[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getUsers()
      .then((users) => {
        this.users = users.filter((user) => user.accessLevel > 4);
        this.refresh();
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.note-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link {
  user-select: none;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.card-size {
  min-width: 600px;
  padding-top: 24px;
  padding-bottom: 24px;
  height: fit-content;
  max-height: calc(100vh - 200px);
}

.card-size a {
  padding: 0px !important;
  justify-self: center;
}

.border {
  border: 1px solid lightgray;
  border-radius: 6px;
}

.item {
  border-radius: 12px;
}

.border-item {
  padding: 16px;
}

.item:hover:not(.selected) {
  background-color: lightgray;
}

.item:hover {
  cursor: pointer;
  user-select: none;
}

.selected {
  background-color: lightskyblue;
  font-weight: bold;
}

.icon-button {
  width: 52px !important;
  height: 42px !important;
}

textarea {
  height: calc(100vh - 208px);
  border-radius: 6px;
}

.chat-icon {
  height: 60px;
  width: 60px;
  align-self: center;
  border-radius: 50%;
  border: 1px solid grey;
  position: relative;
  background-color: white;
}

.chat-icon-initials {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  user-select: none;
}

.people-search-container {
  position: relative;
  align-self: center;
}

.people-search-list {
  top: 50px;
  position: absolute;
  width: 100%;
}

.people-search-item:hover {
  background-color: lightskyblue;
  cursor: pointer;
  text-decoration: underline;
}

.people-search-item {
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  padding: 6px;
  text-align: left;
  background-color: white;
  z-index: 10;
}

.people-search-item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-top: 1px solid gray;
}

.people-search-item:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.people-search {
  width: 260px;
  height: 42px;
}

.recipients .chat-icon {
  width: 40px;
  height: 40px;
}

.recipients .chat-icon:hover {
  background-color: lightgray;
}

.recipients .chat-icon:hover {
  .chat-icon-initials {
    display: none;
  }

  .remove {
    display: block;
  }

  .tooltip {
    opacity: 1;
  }
}

.chat-icon .tooltip {
  position: absolute;
  top: 120%;
  left: 10%;
  transform: translate(0%, 0%);
  background-color: grey;
  color: white;
  border-radius: 6px;
  padding: 6px;
  transition: opacity 0.3s;
  opacity: 0;
  pointer-events: none;
  z-index: 20;
}

.chat-icon .tooltip::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 23%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

.chat-icon .remove {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  user-select: none;
  font-size: 32px;
  color: grey;
}

.container {
  position: relative;
}

.note-state {
  position: absolute;
  user-select: none;
  bottom: 10px;
  right: 50%;
  transform: translate(50%, 0%);
  pointer-events: none;
  background-color: white;
  border: solid 1px gray;
  color: black;
  border-radius: 9px;
  padding: 6px;
}

.note-name {
  padding: 12px;
}
</style>